





























































import {Vue, Component, Ref, Mixins, PropSync, Watch} from 'vue-property-decorator';
import SessionMixin from "@/mixins/SessionMixin";
import Address from "@/models/Address";
import Headquarter from "@/models/Headquarter";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import Handler from "@/handlers/Handler";
import HeadquarterService from "@/services/HeadquarterService";
import AddressService from "@/services/AddressService";
import RulesTool from "@/services/tool/RulesTool";

@Component({})
export default class HeadquarterOrderDataComponent extends Mixins(SessionMixin) {


  @Ref() readonly form!: HTMLFormElement

  get rules() {
    return RulesTool
  }

  @PropSync('address') addressModel!: Nullable<Address>
  @PropSync('headquarter') headquarterModel!: Nullable<Headquarter>
  @PropSync('reference') syncedReference!: Nullable<string>
  @PropSync('description') syncedDescription!: Nullable<string>

  headquarters: MultipleItem<Headquarter> = { items: [], totalItems: 0 }
  addresses: MultipleItem<Address> = {items: [], totalItems: 0}
  loading: boolean = false
  headquarterSearch: string = ""
  headquarterDialog: boolean = false
  addressDialog: boolean = false
  pvp: boolean = false

  lang: any = getModule(LangModule).lang

  created() {
    this.refresh()
  }

  async refresh() {
    await this.getHeadquarters()
  }

  async getHeadquarters() {
    await Handler.getItems(this, this.headquarters, () =>
        HeadquarterService.findByMyCompany(0, 10, this.headquarterSearch)
    )
  }

  @Watch('headquarterModel', {deep: true})
  async getAddresses() {
    if (this.headquarterModel?.id != null) {
      await Handler.getItems(this, this.addresses, () =>
          AddressService.getByHeadquarter(this.headquarterModel?.id!!)
      )
    }
  }

  afterCreatedHeadquarter(headquarter: Headquarter) {
    this.refresh()
    this.headquarterModel = headquarter
    this.headquarterDialog = false
  }

  validateForm() {
    return this.form.validate()
  }

}
