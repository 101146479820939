




















import {Component, Prop, Vue} from "vue-property-decorator"
import Order, { OrderSelect, OrderType } from "@/models/Order"
import OrderService from "@/services/OrderService"
import LangModule from "@/store/LangModule"
import { getModule } from "vuex-module-decorators"

@Component
export default class OrderStatusComponent extends Vue {

  lang: any = getModule(LangModule).lang

  @Prop() readonly orderType!: OrderType
  status = {
    color: "",
    text: ""
  }

  orderTypes: OrderSelect[] = [
    {name: "En Espera", value: OrderType.WAITING},
    {name: "Enviado", value: OrderType.SEND},
    {name: "Finalizado", value: OrderType.FINISH},
    {name: "Borrador", value: OrderType.DRAFT},
  ]

}
