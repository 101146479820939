import SessionTool from "@/services/tool/SessionTool";
import {AuthorityName} from "@/models/Authority";
import {Component, Vue} from "vue-property-decorator";

@Component
export default class SessionMixin extends Vue {

    get session() {
        return SessionTool.getSession()
    }

    get isDistributor() { return SessionTool.hasAuthority(AuthorityName.DISTRIBUTOR) }
    get isCommercial() { return SessionTool.hasAuthority(AuthorityName.COMMERCIAL) }
    get isHeadquarterManager() { return SessionTool.hasAuthority(AuthorityName.HEADQUARTER_MANAGER) }

}