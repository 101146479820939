



























import User from "@/models/User"
import {Component, Prop, Vue, Watch} from "vue-property-decorator"

@Component
export default class AccountBoxComponent extends Vue {
  @Prop() readonly user!: User
  @Prop() readonly icon?: String
}
